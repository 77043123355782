<template>
  <div>
    <div class="settings-header-text">
          Settings
    </div>
    <b-container style="max-width: 80%; padding: 15px">
      
      <div class="row row-cols-1 row-cols-md-3">
        <div class="col mb-4">
          <b-card
            header="Users"
            class="text-center"
          >
            <b-card-text>
              <b-icon variant="info" class="mr-2" font-scale="5" icon="person-fill"></b-icon>
              <br class="mb-4">
              <router-link to="/users/" class="stretched-link">Add or manage users</router-link>
            </b-card-text>
          </b-card>
          
        </div>

        <div class="col mb-4">
          <b-card
            header-bg-variant="white" 
            header="Groups"
            class="text-center"
          >
            <b-card-text>
              <b-icon variant="info" class="mr-2" font-scale="5" icon="people-fill"></b-icon>
              <br class="mb-4">
              Add or manage groups
            </b-card-text>
          </b-card>
        </div>

        <div class="col mb-4">
          <b-card
            header="Cronjob Management"
            class="text-center"
          >
            <b-card-text>
              <b-icon variant="info" class="mr-2" font-scale="5" icon="clock"></b-icon>
              <br class="mb-4">
              <router-link :to="{ name: 'cronjobs-data' }" class="stretched-link">Manage Cronjobs</router-link>
            </b-card-text>
          </b-card>
        </div>
        <div class="col mb-4">
          <b-card
            header="Fee Schedule Data"
            class="text-center"
          >
            <b-card-text>
              <b-icon variant="info" class="mr-2" font-scale="5" icon="box-arrow-in-left"></b-icon>
              <br class="mb-4">
              <router-link :to="{ name: 'fee-schedule-data' }" class="stretched-link">Upload fee schedules</router-link>
            </b-card-text>
          </b-card>
        </div>
        <div class="col mb-4">
          <b-card
            header="Taxonomy Code Data"
            class="text-center"
          >
            <b-card-text>
              <b-icon variant="info" class="mr-2" font-scale="5" icon="journal-medical"></b-icon>
              <br class="mb-4">
              <router-link :to="{ name: 'taxonomy-code-data' }" class="stretched-link">Upload taxonomy codes</router-link>
            </b-card-text>
          </b-card>
        </div>
        <div class="col mb-4">
          <b-card
            header="Payers Holding Tank"
            class="text-center"
          >
            <b-card-text>
              <b-icon variant="info" class="mr-2" font-scale="5" icon="box-arrow-in-left"></b-icon>
              <br class="mb-4">
              <router-link :to="{ name: 'payers-data' }" class="stretched-link">Manage payers data</router-link>
            </b-card-text>
          </b-card>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Admin",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card a {
  color: var(--dark) !important;
}
.card a:hover {
  color: var(--dark) !important;
}
.card:hover { 
    border: 1px solid var(--dark) !important;
  }
.stretched-link {
  text-decoration: none;
}
h1 {
  font-family: sans-serif;
  display: inline;
}
.settings-header-text {
    margin-right:30px;
    font-weight:300;
    font-size:36px;
    color: var(--gray-dark);
}
.card {
    font-size: 20px;
    margin-bottom: 24px;
    border-radius: 15px;
    color: var(--dark) !important;
    border: 1px solid #E6EEF9;
}
.card-height {
    min-height: 350px;
}

.card-header, .card-footer {
    color: var(--dark) !important;
    font-size: 20px;
    padding: 0.75rem 1rem;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.card-footer {
    font-size:0.75rem;
    padding-bottom:10px;
}
</style>